import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js'

@customElement('ctx-content-textarea')
export class CtxContentTextArea extends LitElement {
  static styles = [
    css`
        textarea {
            border: 0.5px solid #d1d5db;
            box-sizing: border-box;
            height: 100%;
            max-height: 100%;
            min-height: 200px;
            overflow-y: auto;
            padding: 0.5rem;
            resize: none;
            width: 100%;
        }
    `
  ];

  @query("#editor") editor: HTMLTextAreaElement;

  @property({ type: Boolean }) readonly: boolean = false;

  @state() cursorPosition: number;
  @state() private _value: string = '';

  get value(): string {
    return this._value;
  }

  set value(newValue: string) {
    this._value = newValue;

    this.dispatchEvent(new CustomEvent("content-changed", { detail:  this._value }));
    this.requestUpdate();
  }

  insertContentAtCursorPosition(content: string) {
    let rawContentHead = this.value.substring(0, this.cursorPosition);
    let rawContentTail = this.value.substring(this.cursorPosition, this.value.length);
    
    this.value = `${ rawContentHead }${ content }${ rawContentTail }`;
    this.editor.value = this.value;

    this.cursorPosition = rawContentHead.length + content.length;
    this.editor.focus();
    this.editor.setSelectionRange(this.cursorPosition, this.cursorPosition);

    this._setCursorPosition();
  }

  private _contentChanged = async (event: InputEvent) => {
    const target = event.target as HTMLTextAreaElement;
    this.value = target.value;
    this._setCursorPosition();
  }

  private _setCursorPosition() {
    this.cursorPosition = this.editor.selectionStart;
  }

  private textAreaClickHandler = async () => {
    this._setCursorPosition();
  }

  render() {
    return html`<textarea ?readonly=${this.readonly} id="editor" class="component" .value=${this.value} @click=${this.textAreaClickHandler} @input=${this._contentChanged}></textarea>`;
  }
}