import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js'
import { orderedList, unOrderedList } from './ctx-content-editor-examples';

@customElement('ctx-content-list-select')
export class CtxContentListSelect extends LitElement {
    static styles = [
        css`
            .flex {
                display: flex;
                gap: 4px;
            }
        `
    ];

    render() {
        return html`<ctx-content-select headingIcon="format_list_bulleted" heading="Lists">
            <ctx-content-select-option value=${orderedList}>
                <div class="flex">
                    <mwc-icon>format_list_numbered</mwc-icon>
                    Ordered Lists
                </div>
            </ctx-content-select-option>
            <ctx-content-select-option value=${unOrderedList}>
                <div class="flex">
                    <mwc-icon>format_list_bulleted</mwc-icon>
                    Unordered Lists
                </div>
            </ctx-content-select-option>
        </ctx-content-select>`;
    }
}