import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js'
import { InsertEditorContentEvent } from './insert-editor-content-event';

@customElement('ctx-content-toolbar')
export class CtxContentToolbar extends LitElement {
  static styles = [
    css`
        .grow {
          align-items: center;
          display: flex;
          flex-grow: 1;
          justify-content: right;
        }

        .help-icon {
          color: #111827;
        }

        .help-icon:hover {
          cursor: pointer;
          color: #0069d9;
        }

        .help-icon-container {
          padding-top: 0.5rem;
        }

        select {
            border: 0.5px solid #d1d5db;
            padding: 0.250rem;
        }

        .toolbar {
            align-items: center;
            background-color: #f9fafb;
            border: 0.5px solid #d1d5db;
            box-sizing: border-box;
            display: flex;
            gap: 0.5rem;
            margin-bottom: 0.250rem;
            padding: 0.5rem;
            width: 100%;
        }
    `
  ];

  private _selectedToolbarContentChanged = async (event: CustomEvent) => {
    this.dispatchEvent(new InsertEditorContentEvent(event.detail.value))
  }

  constructor() {
    super();
    this.addEventListener('selected-content-changed', this._selectedToolbarContentChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('selected-content-changed', this._selectedToolbarContentChanged);
  }

  render() {
    return html`<div class="toolbar">
        <slot></slot>
        ${this._renderRightSlot()}
    </div>`;
  }

  private _renderHelpIcon() {
    return html`<div class="help-icon-container">
      <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">
        <mwc-icon class="help-icon">help</mwc-icon>
      </a>
    </div>`;
  }

  private _renderRightSlot() {
    return html`<div class="grow">
      <slot name="right"></slot>
      ${ this._renderHelpIcon() }
    </div>`;
  }
}