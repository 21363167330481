import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js'
import { snippets } from './ctx-snippets';

@customElement('ctx-content-snippets-select-option')
export class CtxContentSnippetsSelectOption extends LitElement {
  static styles = [
    css`
        .flex {
          align-items: center;
          display: flex;
          gap: 0.25rem;
        }

        .option {
          cursor: pointer;
        }

        .option:hover {
          background-color: #f3f4f6;
        } 
    `
  ];

  @property() snippet: string;

  private _selected = async () => {
    this.dispatchEvent(new CustomEvent("option-selected", { detail: {
      value: snippets[this.snippet].value
    }, bubbles: true, composed: true }));
  }

  render() {
    return html`<div class="option">
      ${this._renderDisplayContent()}
    </div>`;
  }

  private _renderDisplayContent() {
    return html`<div class="flex" @click=${this._selected}>${this._renderDisplayIcon()} ${ snippets[this.snippet].description }</div>`;
  }

  private _renderDisplayIcon() {
    return html`<mwc-icon>${ snippets[this.snippet].icon }</mwc-icon>`;
  }
}