// Headings
export const heading1 = `# Heading 1\n`;
export const heading2 = `## Heading 2\n`;
export const heading3 = `### Heading 3\n`;
export const heading4 = `#### Heading 4\n`;
export const heading5 = `##### Heading 5\n`;
export const heading6 = `###### Heading 6\n`;

// Lists
export const orderedList = `1. Item 1
2. Item 2
    a. Item 2A
    b. Item 2B
3. Item 3\n`;

export const unOrderedList = `* Item 1
* Item 2
    * Item 2A
    * Item 2B
* Item 3\n`;

// Tables
export const table = `|Heading 1|Heading 2|Heading 3|
|---------|---------|---------|
|Row 1, Column 1|Row 1, Column 2|Row 1, Column 3|
|Row 2, Column 1|Row 2, Column 2|Row 2, Column 3|\n`;

export const tableOfContents = `## Table of Contents

* [Section 1](#section-1)
* [Section 2](#section-2)
    * [Section 1A](#section-1-a)
    * [Section 1B](#section-1-b)
* [Section 3](#section-3)\n`;


// Components
export const hero = `<ctx-hero 
    title="An example hero component" 
    description="An example description." 
    call-to-action="Read more about the included image" 
    call-to-action-url="https://picsum.photos/" 
    src="/images/DatacraftAnalytics-Icon-Final-RGB-twitter-min.png">
         You have the option to add additional custom content.
</ctx-hero>\n`;
export const informationMessage = `<alert-message level='information'>The information goes here</alert-message>\n`;
export const warningMessage = `<alert-message level='warning'>The warning goes here</alert-message>\n`;
export const errorMessage  = `<alert-message level='error'>The error goes here</alert-message>\n`;

// Documentation
export const documentation = `# An example of a document layout

## Table of contents

* [Creating a table of contents](#creating-a-table-of-contents)
* [Creating headings](#creating-headings)
* [Creating lists](#creating-lists)
    * [Creating an ordered list](#creating-an-ordered-list)
    * [Creating an unordered list](#creating-an-unordered-list)
* [Creating tables](#creating-tables)
* [Creating alerts](#creating-alerts)
    * [Creating information alerts](#creating-information-alerts)
    * [Creating warning alerts](#creating-warning-alerts)
    * [Creating error alerts](#creating-error-alerts)

## Creating a table of contents

A table of contents begins with a heading, followed by a list of sections. Each section must start with the '*' character, followed by a description enclosed in square brackets and the ID of the corresponding heading. The ID should start with the '#' character, followed by the exact wording of the heading, with spaces replaced by '-' characters.

## Creating headings

Headings are created by placing the '#' character followed by a space at the beginning of the text. You can create headings from Level 1 to Level 6 by increasing the number of '#' characters; more '#' characters indicate a smaller heading.

${ heading4 }
${ heading5 }
${ heading6 }

## Creating lists

A list is a collection of items that can start with either a numerical value or the '*' character, depending on the type of list. Below are examples of how to create both ordered and unordered lists.

### Creating an ordered list

${ orderedList }

### Creating an unordered list

${ unOrderedList }


## Creating tables

Tables are used to organise data into rows and columns. They are constructed by arranging headings, rows, and columns, with each element separated by the '|' character, as illustrated below.

${ table }

## Creating alerts

Alerts are components used to convey standard information or to notify users about warnings or errors. To display a specific type of alert, set the level property to either 'information', 'warning', or 'error'.

### Creating information alerts

${ informationMessage }

### Creating warning alerts

${ warningMessage }

### Creating error alerts

${ errorMessage }\n`;