import { documentation, errorMessage, hero, informationMessage, tableOfContents, warningMessage } from "./ctx-content-editor-examples";

export const snippets: any = {
    documentLayout: {
        icon: 'dashboard',
        description: 'Document Layout',
        value: documentation
    },
    errorAlert: {
        icon: 'cancel',
        description: 'Error Message',
        value: errorMessage
    },
    hero: {
        icon: 'filter',
        description: 'Hero',
        value: hero
    },
    infoAlert: {
        icon: 'info',
        description: 'Info Message',
        value: informationMessage
    },
    tableOfContents: {
        icon: 'format_list_bulleted',
        description: 'Table of Contents',
        value: tableOfContents
    },
    warningAlert: {
        icon: 'warning',
        description: 'Warning Message',
        value: warningMessage
    }
}